<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item :to="`/`">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item active> View Drum Picking </b-breadcrumb-item>
    </b-breadcrumb>
    <br />
    <b-modal id="promptDrum" ref="modal" v-model="promptDrum" :title="title">
      <b-form-group label="Fetching" label-for="form-fetching">
        <b>{{ form.orderNumber }}</b>
      </b-form-group>

      <b-form-group label="Cable" label-for="form-cableID">
        <b>{{ form.cableID ? form.cableID.itemName : "" }}</b>
      </b-form-group>

      <b-form-group
        v-if="actionType !== 'reject' && actionType !== 'approve'"
        label="Drum"
        label-for="form-drumID"
      >
        <v-select
          placeholder="----- Select Drum -----"
          :options="drumOption(form.cableID ? form.cableID.item : '')"
          :clearable="false"
          label="name"
          v-model="form.drumID"
        />
      </b-form-group>

      <b-form-group
        v-if="actionType === 'approve' || actionType === 'reject'"
        label="Drum"
        label-for="form-drumID"
      >
        <b>{{ form.cableID ? form.cableID.drumName : "" }}</b>
      </b-form-group>

      <template #modal-footer>
        <b-button size="md" variant="success" @click="send">
          Save
        </b-button>
        <b-button size="md" variant="danger" @click="promptDrum = false">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-card title="View Drum Picking Detail">
      <b-col cols="12">
        <b-row class="mb-2">
          <b-col cols="1">
            <b-button size="md" variant="primary" @click="goBack()"
              >Back</b-button
            >
          </b-col>
          <b-col cols="3" v-if="form.status === 'Approved' && form.props.type === 'Direct' && form.props.waitingForMachine !== true">
            <!-- <b-button
              variant="success"
              class="mr-1"
              @click ="toPacking"
              >To Packing</b-button
            > -->
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><strong>Job Order</strong></b-col>
          <b-col>: {{ form.props? form.props.jobOrderNumber : ''}}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><strong>Fetching</strong></b-col>
          <b-col>: {{ form.orderNumber ? form.orderNumber : "" }}</b-col>
        </b-row>
        <b-row v-if="form.props.babyDrumType == undefined">
          <b-col cols="2"><strong>Machine Name</strong></b-col>
          <b-col>: {{ machine? machine.name : ''}}</b-col>
        </b-row>
        <b-row v-if="form.props.babyDrumType != undefined">
          <b-col cols="2"><strong>Machine Name</strong></b-col>
          <b-col>: {{ station? station.name : '' }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><strong>Created at</strong></b-col>
          <b-col>: {{ form.dateFormatted ? form.dateFormatted : "" }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><strong>Status</strong></b-col>
          <b-col>: {{ form.status == null ? "No Status" : form.status }}</b-col>
        </b-row>
        <b-row v-if="form.props.babyDrumType != undefined">
          <b-col cols="2"><strong>Pick List Number</strong></b-col>
          <b-col>: {{ form.props? form.props.pickListNumber : '' }}</b-col>
        </b-row>
        <b-row v-if="form.props.babyDrumType != undefined">
          <b-col cols="2"><strong>Baby Drum Type</strong></b-col>
          <b-col>: {{ form.props? form.props.babyDrumType : '' }}</b-col>
        </b-row>
        <b-row v-if="form.props.babyDrumType != undefined">
          <b-col cols="2"><strong>Baby Drum Size</strong></b-col>
          <b-col>: {{ form.props? form.props.babyDrumSize : '' }}</b-col>
        </b-row>
        <b-row v-if="form.status != 'Created'">
          <b-col cols="2"><strong>Picking Start Time</strong></b-col>
          <b-col>: {{ job? job.props.MDS : '' }}</b-col>
        </b-row>
        <b-row v-if="form.status != 'Created'">
          <b-col cols="2"><strong>Picking End Time</strong></b-col>
          <b-col>: {{ job? job.props.MDE : '' }}</b-col>
        </b-row>
        <b-row style="margin-top:10px">
          <b-col cols="12">
            <b-table
              v-if="form.props.babyDrumType == undefined"
              head-variant="dark"
              :fields="fieldsTable"
              :items="form.item"
              small
              responsive
            >
              <template v-slot:cell(actions)="row">
                <p v-if="row.item.status === 'Approved'">-</p>
                <b-button
                  @click="
                    index = row.index;
                    form.cableID = row.item;
                    promptDrum = true;
                    actionType = 'select';
                  "
                  size="sm"
                  variant="primary"
                  class="mr-1"
                  v-if="
                    row.item.status === 'Created' ||
                      row.item.status === 'Rejected'
                  "
                  >Select</b-button
                >
                <b-button
                  @click="
                    index = row.index;
                    form.cableID = row.item;
                    promptDrum = true;
                    actionType = 'approve';
                  "
                  size="sm"
                  variant="success"
                  class="mr-1"
                  v-if="row.item.status === 'Fetching'"
                  >Approve Drum</b-button
                >
                <b-button
                  @click="
                    index = row.index;
                    form.cableID = row.item;
                    promptDrum = true;
                    actionType = 'reject';
                  "
                  size="sm"
                  variant="danger"
                  class="mr-1"
                  v-if="row.item.status === 'Fetching'"
                  >Reject Drum</b-button
                >
              </template>
              <template v-slot:cell(status)="row">
                <b v-if="row.item.status === 'Rejected'" style="color:red">{{
                  row.item.status
                }}</b>
                <b v-if="row.item.status === 'Approved'" style="color:green">{{
                  row.item.status
                }}</b>
                <b
                  v-if="row.item.status === 'Fetching'"
                  style="color:#3493d2"
                  >{{ row.item.status }}</b
                >
                <b v-if="row.item.status === 'Created'">{{
                  row.item.status
                }}</b>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </div>
</template>
<script>
import axios from "@/axios";
import { mapGetters, mapActions } from "vuex";
import { dateFormat, userAccess, viewAccess } from "@/utils/utils.js";
export default {
  props: ["id"],
  data() {
    return {
      form: {},
      station: {},
      machine: {},
      job: {},
      index: 0,
      promptDrum: false,
      title: "",
      actionType: "",
      fieldsTable: [
        { key: "orderNumber", label: "Pick List" },
        { key: "pickList.orderNumber", label: "SO Number" },
        { key: "lineItemDescription", label: "Line Item And Description"},
        { key: "pickList.cableLengthInfo", label: "Cable Length" },
        { key: "pickList.fromBatch", label: "Drum" },
        { key: "pickList.cuttingNumber", label: "Cutting Number" },
        { key: "pickList.binLocation", label: "Bin Location" },
      ],
    };
  },
  computed: {
    jobOrderById() {
      return this.$store.getters['joborder/getJobOrderId']
    },
  },
  methods: {
    ...mapActions({
      getJobOrder: "joborder/getJobOrder",
    }),
    drumOption(item) {
      let filteredMamaDrum = this.$store.getters["bom/getComponents"].filter(
        (comp) => {
          if (comp.props["components"] && !comp.props.babyDrum) {
            let components = comp.props.components.map((el) => {
              return el.id;
            });
            if (components.includes(item)) {
              if (
                comp.props.status === undefined ||
                comp.props.status === "Free"
              ) {
                return true;
              }
            }
          }
        }
      );
      return filteredMamaDrum;
    },
    disableSelectButton(item) {
      if (item !== undefined) {
        var status = item.map((el) => {
          return el.status;
        });
        status.every(isApproved);

        function isApproved(status) {
          return status !== "Approved";
        }

        if (status.every(isApproved) === true) {
          return true;
        }
      }
      return false;
    },
    finishPacking(){
      let sendData = this.form
      sendData.status = 'Fetch Finish'
      
      this.$store
        .dispatch("fetch/updateFetch", {
          id: sendData.id,
          data: sendData,
        })
        .then(() => {
          this.$bvToast.toast("Successfully change status to 'Fetch Finish'", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.$store
            .dispatch("fetch/getFetch")
            .catch((err) => console.log(err));
        });
    },
    goBack() {
      window.history.back()
    },
  },
  mounted() {
    this.$store.dispatch('fetch/getFetchDetailId', { id: this.id }).then((res) => {
    
    this.form = res

    console.log("thisform", this.form)

    this.form.item.map((item) => {
      (item.lineItemDescription = item.pickList.lineItem + "," + item.pickList.lineDescription);
      })

    var originalDate = new Date(this.form.createdAt);
    this.form.dateFormatted = `${originalDate.getDate()}/${originalDate.getMonth() + 1}/${originalDate.getFullYear(). toString().slice(-2)} ${originalDate.getHours()}:${originalDate.getMinutes()}:${originalDate.getSeconds()}`;

    if (this.form.props.babyDrumType != undefined){
      this.$store.dispatch("plant/getStationId", { id: this.form.props.station }).then((res) => {
        this.station = this.$store.getters['plant/getStationId'];
      })
    }

    this.$store.dispatch("plant/getStationId", { id: this.form.machine }).then((res) => {
    this.machine = this.$store.getters['plant/getStationId'];
    })


      this.$store.dispatch('joborder/getJobOrderId', {id: this.form.JobOrderID}).then((res) => {

        this.job = this.$store.getters['joborder/getJobOrderId'];
        
        var newMDS = dateFormat(this.job.props.MDS).split(",")
        var newMDE = dateFormat(this.job.props.MDE).split(",")

        this.job.props.MDS = newMDS[0] + newMDS[1].replace(/\./g, ':'),
        this.job.props.MDE = newMDE[0] + newMDE[1].replace(/\./g, ':')
      })

      if (this.form.props.babyDrumType == "W"){
        return this.form.props.babyDrumType = "Wooden"
      }
      else if (this.form.props.babyDrumType == "S"){
        return this.form.props.babyDrumType = "Steel"
      }
      if (this.form.props.babyDrumType == "A"){
        return this.form.props.babyDrumType = "Active"
      }
      else if (this.form.props.babyDrumType == "I"){
        return this.form.props.babyDrumType = "Inactive"
      }
    })
  },
};
</script>
